
<template>
  <div class="video">
    <h1
      class="title_top"
      v-html="
        ((subjectArr[currentSubjectIndex] &&
          subjectArr[currentSubjectIndex].subjectName) ||
          '') + '视频教程合集'
      "
    ></h1>
    <!-- <span>精选高能视频，前沿技术点一网打尽</span> -->
    <div class="inner">
      <div
        :class="[
          subjectToCn(currentSubjectIndex) + subsufToCn(index),
          'content',
        ]"
        v-if="video.subjectStageCourseEntities.length > 0"
        v-for="(video, index) in roadmap"
      >
        <div class="head">
          <span v-cloak>第{{ numToCn(index) }}阶段</span>
          <span v-html="removeHTMLTag(video.stageDec)"> </span>
        </div>
        <div class="course">
          <a
            @click="jumptoVideoDetail(item)"
            class="item"
            v-for="item in video.subjectStageCourseEntities"
            style="display: inline-block; color: #333"
            :href="`/video/${subjectId}/${item.id}` + addHrefSearch()"
            target="_blank"
          >
            <div class="shade_box">
              <div class="playbtn">
                <i class="el-icon-video-play"></i>
              </div>
              <div class="desc">
                <p v-text="item.courseDec"></p>
              </div>
            </div>
            <div class="container">
              <img :src="item.url" :alt="subjectName + '视频教程'" />
            </div>
            <div :title="item.courseName" v-html="item.courseName" class="name">
              <!-- HTML+CSS基础入门 -->
            </div>
            <div class="message">
              <span
                ><img src="~@/assets/images/icons/number.png" :alt="kwAlt1" />
                <span v-html="item.studyNum"></span>人学习</span
              >
              <em :class="'levelColor' + item.level">
                <span class="icon"></span>
                <i v-html="formatLevel(item.level)"></i>
              </em>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoList",
  props: {
    subjectArr: Array,
    roadmap: Array,
    subjectId: Number,
    currentSubjectIndex: Number,
  },
  data() {
    return {};
  },
  computed: {
    subjectName() {
      return this.subjectArr[this.currentSubjectIndex].subjectName;
    },
    kwAlt1() {
      if (this.subjectId === 13) return "软件测试自学全套教程";
      if (this.subjectId === 8) return "云计算入门教程";
      if (this.subjectId === 4) return "Python入门教程";
      if (this.subjectId === 2) return "物联网视频";
      return this.subjectName + "教程";
    },
  },
  methods: {
    // 标签宽度调整
    line_width(str) {
      // 计算字符长度，汉字长度为2
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      if (len > 14) return "284px";
      return "130px";
    },
    jumptoVideoDetail(e) {
      return;
      this.$router.push({
        path: `/video/${this.subjectId}/${e.id}`,
        query: this.$route.query,
      });
    },
    //锚点名转化
    subjectToCn(indexF) {
      const rt = [
        "java",
        "web",
        "bigdata",
        "python",
        "ui",
        "iot",
        "linux",
        "softwaretest",
        "unity",
        "php",
        "newmedia",
        "videoclip",
      ];
      return rt[indexF] || "/";
    },
    numToCn(i) {
      const rt = ["一", "二", "三", "四", "五"];
      return rt[i] || "";
    },
    subsufToCn(index) {
      const rt = ["_jichu", "_jingjie", "_gaoji", "_xiangmu"];
      return rt[index] || "";
    },
  },
};
</script>

<style scoped lang='less'>
//学习视频
.video {
  width: 100%;

  .inner {
    margin-top: 36px;
    .content {
      width: 100%;
      margin-bottom: 20px;
      // padding-bottom: 10px;
      margin-left: 0; //覆盖样式
      &:last-child {
        //解决底部略微空白问题
        margin-bottom: -20px;
      }

      .head {
        height: 30px !important;
        margin-bottom: 23px;
        box-sizing: border-box;
        background: #fff;
        line-height: 30px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        span {
          &:first-child {
            width: 125px;
            padding-left: 26px;
            margin-right: 15px;
            color: #ffffff;
            background: #4c8bdc;
            -webkit-clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
          }
          &:last-child {
            color: #000;
          }
        }
      }

      .course {
        /*         display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 222px); */
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-right: 23px;
          width: 222px;
          height: 186px;
          margin-bottom: 16px;
          cursor: pointer;
          overflow: hidden;
          border-radius: 10px;
          position: relative;
          background-color: #fff;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .shade_box {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1050;
            width: 100%;
            height: 0;
            .playbtn {
              width: 100%;
              height: 70px;
              text-align: center;
              background-color: rgba(0, 0, 0, 0.7);
              i {
                font-size: 34px;
                color: #fff;
                line-height: 70px;
              }
            }
            .desc {
              height: calc(100% - 70px);
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                max-width: 193px;
                max-height: 74px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                line-height: 18px;
                /*设置省略号在容器第四行文本后*/
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
              }
            }
          }
          &:hover {
            .shade_box {
              // bottom: 0;
              height: 100%;
              transition: height 0.3s ease;
            }
            .container img {
              transform: scale(1.2);
              transition: all 0.3s ease;
            }
          }

          .container {
            width: 222px;
            height: 122px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            margin: 10px 0 0 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .message {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 9px 10px 10px 9px;

            span {
              font-size: 13px;
              color: #666666;
              display: flex;
              align-items: center;

              img {
                width: 11px;
                height: 12px;
                margin-right: 5px;
              }
            }

            em {
              background: #db0e1a;
              border-radius: 18px;
              padding: 1px 8px 1px 5px;
              font-size: 14px;
              color: #ffffff;
              display: flex;
              align-items: center;

              span.icon {
                display: inline-block;
                width: 13px;
                height: 13px;
                margin-right: 4px;
                background: url("~@/assets/images/icons/level1.png") no-repeat;
                background-size: 100% 100%;
              }
              &.levelColor2 span.icon {
                background-image: url("~@/assets/images/icons/level2.png");
              }
              &.levelColor3 span.icon {
                background-image: url("~@/assets/images/icons/level3.png");
              }
              &.levelColor4 span.icon {
                background-image: url("~@/assets/images/icons/level4.png");
              }
              &.levelColor5 span.icon {
                background-image: url("~@/assets/images/icons/level5.png");
              }
            }
          }
        }
      }
    }
  }
}
</style>