<template>
  <div class="study-roadmap roadLine" :id="subjectToCn(currentSubjectIndex)">
    <h2 class="title_top">
      <strong
        v-html="
          ((subjectArr[currentSubjectIndex] &&
            subjectArr[currentSubjectIndex].subjectName) ||
            '') + '学习路线'
        "
      ></strong>
    </h2>
    <p class="title_adv">
      聚焦行业热门技术，通过贴近实战的
      <a
        :href="
          'http://video.mobiletrain.org' +
          subjectRouteArr[currentSubjectIndex] +
          '/'
        "
        >{{ subjectArr[currentSubjectIndex].subjectName }}视频教程
      </a>
      ，3个月从入门到精通，视频+笔记+工具+源码，企业级实战项目免费放送
    </p>
    <div class="inner">
      <div v-for="(item, indexF) in roadmap" class="stage">
        <div class="left">
          <p v-cloak>第{{ numToCn(indexF) }}阶段</p>
          <h3 v-html="item.stageDec"></h3>
        </div>
        <div class="right">
          <div
            v-for="(itemInner, index) in item.subjectStageLineEntityList"
            class="item"
          >
            <span
              @click="scrollMethod(indexF, itemInner.existCourse)"
              :title="itemInner.lineName"
              :style="{ width: line_width(itemInner.lineName) }"
              v-text="itemInner.lineName"
            >
              <!-- <a
                :href="
                  '#' + subjectToCn(currentSubjectIndex) + subsufToCn(indexF)
                "
                @click="isSkip($event, itemInner.existCourse)"
              ></a> -->
            </span>
            <img
              v-if="index !== item.subjectStageLineEntityList.length - 1"
              src="~@/assets/images/icons/arrow-right.png"
              :alt="keyAlt"
            />
          </div>
        </div>
      </div>
    </div>
    <el-empty description="暂无数据" v-if="!roadmap.length"></el-empty>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RoadLines",
  props: {
    subjectArr: Array,
    roadmap: Array,
    currentSubjectIndex: Number,
  },
  data() {
    return {};
  },
  computed: {
    subjectName() {
      return this.subjectArr[this.currentSubjectIndex].subjectName;
    },
    keyAlt() {
      if (this.currentSubjectIndex === 1) return "html5视频教程";
      if (this.currentSubjectIndex === 3) return "Python视频";
      if (this.currentSubjectIndex === 4) return "UI设计教程";
      if (this.currentSubjectIndex === 5) return "嵌入式开发教程";
      if (this.currentSubjectIndex === 6) return "Linux教程";
      if (this.currentSubjectIndex === 7) return "软件测试教程";
      return this.subjectName + "培训课程";
    },
    ...mapState({
      subjectRouteArr: (store) => store.sj.subjectRouteArr,
    }),
  },
  methods: {
    // 标签宽度调整
    line_width(str) {
      // 计算字符长度，汉字长度为2
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      if (len > 14) return "284px";
      return "130px";
    },
    //判断是否跳转
    /*     isSkip(ev, es) {
      if (!es) return ev.preventDefault(); //阻止默认行为
    }, */
    scrollMethod(index, ex) {
      if (ex === 0) return false;
      const dd = document.querySelector(
        "." +
          this.subjectToCn(this.currentSubjectIndex) +
          this.subsufToCn(index)
      );
      dd.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
    /*     scrollMethodRoad() {
      const dd = document.getElementById("roadLine");
      dd.scrollIntoView();
    }, */
    //锚点名转化
    subjectToCn(indexF) {
      const rt = [
        "java",
        "web",
        "bigdata",
        "python",
        "ui",
        "iot",
        "linux",
        "softwaretest",
        "unity",
        "php",
        "newmedia",
        "videoclip",
      ];
      return rt[indexF] || "/";
    },
    numToCn(i) {
      const rt = ["一", "二", "三", "四", "五"];
      return rt[i] || "";
    },
    subsufToCn(index) {
      const rt = ["_jichu", "_jingjie", "_gaoji", "_xiangmu"];
      return rt[index] || "";
    },
  },
};
</script>

<style scoped>
</style>