<template>
  <div class="aside">
    <h2>各科学习路线</h2>
    <div class="subject">
      <div
        :class="{ active: index === currentSubjectIndex }"
        @click="chosedSubject(index, item)"
        :key="item.id"
        v-for="(item, index) in subjectArr"
        class="des"
        :title="item.subjectName"
      >
        <!-- <img :src="item.url" :alt="item.subjectName" /> -->
        <h2 :title="item.subjectName + '视频教程'" v-cloak>
          {{ item.subjectName }}
          <i
            class="el-icon-caret-right"
            v-show="index === currentSubjectIndex"
          ></i>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AsideNav",
  props: {
    currentSubjectIndex: Number,
    subjectArr: Array,
  },
  computed: {
    ...mapState({
      subjectRouteArr: (store) => store.sj.subjectRouteArr,
    }),
  },
  data() {
    return {};
  },
  methods: {
    chosedSubject(idx, item) {
      const r = this.subjectRouteArr[idx];
      document.querySelector(".mainContent").scrollIntoView();
      if (this.$route.path === r) return;
      this.$router.push({
        path: r + this.addHrefSearch(),
        query: this.$route.query,
      });
    },
  },
  created() {},
};
</script>

<style scoped lang='less'>
.aside {
  //粘性定位
  position: sticky;
  top: 10px;

  float: left;
  width: 182px;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  & > h2 {
    height: 47px;
    background: #4c8bdc;
    font-size: 20px;
    color: #ffffff;
    line-height: 47px;
    text-align: center;
  }
  .subject {
    padding: 18px 0;
    .des {
      height: 40px;
      padding-left: 20px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: rgba(76, 139, 220, 0.1);
        transition: all ease 0.3s;
      }
      h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
      }
      .el-icon-caret-right {
        position: absolute;
        right: 10px;
        top: 13px;
        text-align: center;
        color: rgba(76, 139, 220, 1);
      }
    }
    .active {
      color: #4c8bdc;
      background-color: rgba(76, 139, 220, 0.1);
      h2 {
        font-size: large;
      }
    }
  }
}
</style>