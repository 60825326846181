var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video"},[_c('h1',{staticClass:"title_top",domProps:{"innerHTML":_vm._s(
      ((_vm.subjectArr[_vm.currentSubjectIndex] &&
        _vm.subjectArr[_vm.currentSubjectIndex].subjectName) ||
        '') + '视频教程合集'
    )}}),_c('div',{staticClass:"inner"},_vm._l((_vm.roadmap),function(video,index){return (video.subjectStageCourseEntities.length > 0)?_c('div',{class:[
        _vm.subjectToCn(_vm.currentSubjectIndex) + _vm.subsufToCn(index),
        'content',
      ]},[_c('div',{staticClass:"head"},[_c('span',{},[_vm._v("第"+_vm._s(_vm.numToCn(index))+"阶段")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.removeHTMLTag(video.stageDec))}})]),_c('div',{staticClass:"course"},_vm._l((video.subjectStageCourseEntities),function(item){return _c('a',{staticClass:"item",staticStyle:{"display":"inline-block","color":"#333"},attrs:{"href":`/video/${_vm.subjectId}/${item.id}` + _vm.addHrefSearch(),"target":"_blank"},on:{"click":function($event){return _vm.jumptoVideoDetail(item)}}},[_c('div',{staticClass:"shade_box"},[_vm._m(0,true),_c('div',{staticClass:"desc"},[_c('p',{domProps:{"textContent":_vm._s(item.courseDec)}})])]),_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":item.url,"alt":_vm.subjectName + '视频教程'}})]),_c('div',{staticClass:"name",attrs:{"title":item.courseName},domProps:{"innerHTML":_vm._s(item.courseName)}}),_c('div',{staticClass:"message"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/icons/number.png"),"alt":_vm.kwAlt1}}),_c('span',{domProps:{"innerHTML":_vm._s(item.studyNum)}}),_vm._v("人学习")]),_c('em',{class:'levelColor' + item.level},[_c('span',{staticClass:"icon"}),_c('i',{domProps:{"innerHTML":_vm._s(_vm.formatLevel(item.level))}})])])])}),0)]):_vm._e()}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"playbtn"},[_c('i',{staticClass:"el-icon-video-play"})])
}]

export { render, staticRenderFns }